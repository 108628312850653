import axios, { AxiosInstance } from 'axios';

export class AxiosTransport implements TransportInterface {
  server: AxiosInstance;

  /**
   * set interceptors
   */
  constructor(params: TransportParams) {
    this.server = axios.create({
      baseURL: params.apiUrl,
      timeout: params.timeout,
      headers: {},
    });

    this.server.interceptors.request.use(params.prepareRequest, params.prepareError);
    this.server.interceptors.response.use(params.prepareResponse, params.prepareError);
  }

  async call(method: string, params?: any, type = 'post') {
    switch (type) {
      default:
      case 'post':
        return await this.server.post(method, params);
      case 'put':
        return await this.server.put(method, params);
      case 'delete':
        return await this.server.delete(method, params);
      case 'get':
        return await this.server.get(method, params);
    }
  }

  async get(method: string, params: any) {
    return await this.call(method, { params: params }, 'get');
  }

  async post(method: string, params: any) {
    return await this.call(method, params);
  }

  async put(method: string, params: any) {
    return await this.call(method, params, 'put');
  }

  async delete(method: string, params: any) {
    return await this.call(method, params, 'delete');
  }
}
