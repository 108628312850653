import { AxiosResponse, AxiosError } from 'axios';

export class JSONProtocol implements ProtocolInterface {
  getErrorMessage;

  constructor(params: ProtocolParams) {
    this.getErrorMessage = params.getErrorMessage;
  }

  /**
   * get packet
   * @param method
   * @param data
   * @returns
   */
  createPacket(__method: string, data?: any) {
    return data;
  }

  /**
   * decode packet
   * @param {*} res
   * @returns
   */
  decodePacket(res: AxiosResponse, isLoginMethod: boolean) {
    let out = {};
    let error = false;
    let errorMsg = '';
    let is403 = false;
    let is401 = false;

    // http errors
    is403 = res.status === 403 && isLoginMethod;
    is401 = res.status === 401 && isLoginMethod;

    // api errors
    const data = res.data;

    if (!error) {
      out = data;
    } else {
      out = errorMsg;
    }

    return { packet: out, error, is403, is401 };
  }

  /**
   * get error
   * @param res
   */
  getError(error: AxiosError<any>) {
    let out = '';
    if (error === undefined || error === null) {
      return out;
    }
    if (error.response) {
      out = error.response?.data?.message || error.response.status;
    } else if (error.request) {
      out = error.code ? error.code : 'unknown';
    } else {
      out = error.code ? error.code : 'unknown';
    }
    out = out.toString();

    return this.getErrorMessage ? this.getErrorMessage(out) : out;
  }
}
