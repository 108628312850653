import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Provider } from 'react-redux';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

import './index.css';
import { store } from 'libs/data-store';
import App from 'app/app';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <Provider store={store}>
    <StrictMode>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ReactNotifications />
        <App />
      </LocalizationProvider>
    </StrictMode>
  </Provider>
);
