/**
 * User service
 *
 * @summary implements API calls
 */
import { api } from 'api';
import { notify } from 'libs/common';
import { store, userActions } from 'libs/data-store';
import { UserService } from './user-service.class';

/**
 * set user info
 * @param data
 */
const setUser = (data: any) => {
  store.dispatch(userActions.setUser(data));
};

/**
 * logout
 */
const logout = () => {
  store.dispatch(userActions.logout());
  api.logout();
};

const actions = {
  notify,
  setUser,
  logout,
};

export const userService = new UserService({ api, actions });
export default userService;
