/**
 * Rfid service
 *
 * @summary implements API calls
 */
import { api } from 'api';
import { notify } from 'libs/common';

/**
 * get rfids
 * @param data
 */
export const getRfidItems = async (from: number, limit: number, filter?: Record<string, any>) => {
  let params = { offset: from, limit };
  let err = false;
  if (filter) {
    params = { ...params, ...filter };
  }
  const res = await api.get('rfid/operations', params).catch((error) => {
    err = true;
    notify('Получение списка операций', error.toString());
  });

  if (err) {
    return { items: [], count: 0 };
  }
  //res.splice(20, 700);
  return { items: res.results, count: res.count };
};

/**
 * donwload rfids
 * @param data
 */
export const downloadRfidItems = async (filter?: Record<string, any>) => {
  let err = false;
  let params = {};
  if (filter) {
    params = { ...params, ...filter };
  }
  const res = await api.get('rfid/operations/load', params).catch((error) => {
    err = true;
    notify('Загрузка списка операций', error.toString());
  });

  if (err || !res.link) {
    return '';
  }
  //res.splice(20, 700);
  return res.link;
};
