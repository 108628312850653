//import { hmac } from 'libs/common';

export type IUserServiceActions = {
  notify: any;
  setUser: (data: any) => void;
  logout: () => void;
};

export type IUserService = {
  api: any;
  actions: IUserServiceActions;
};

/**
 * service for interaction with user api
 *
 * @class UserService
 */
export class UserService {
  role: string = '';
  id: string = '';
  api: any;
  actions: any;
  /**
   *
   * @param {*} param0 = {
   *  api - backend api
   *  actions - notify, logout, setUser
   * }
   */
  constructor({ api, actions }: IUserService) {
    this.api = api;
    this.actions = actions;
  }

  /**
   * 1. Check user token
   */
  async checkToken() {
    // if no token return
    if (!this.api.getToken()) {
      this.actions.logout();
      return Promise.resolve();
    }

    // get info
    return await this.getUser();
  }

  /**
   * get user
   */
  async getUser() {
    return await this.api.get('auth/me').then((data: UserMeResponse) => {
      //console.log('🚀 ~ result ~ data:', data);
      if (data) {
        this.id = data.id;
        this.role = data.role;
      }
      this.actions.setUser(data);
      return data;
    });
  }

  /**
   * Login request
   * @param params
   * @returns promise
   */
  async login(params: UserLoginParams) {
    let err = false;
    let errResult = '';
    await this.api.post('auth/login', params).catch((result: any) => {
      err = true;
      errResult = result;
      //console.log('🚀 ~ login ~ result', result);
    });

    if (err) {
      return { error: errResult };
    }

    // get info
    return await this.getUser();
  }

  /**
   * logout request
   */
  async logout() {
    this.actions.logout();
  }
}
