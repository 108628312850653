import { Navigate } from 'react-router-dom';

import { LayoutBase, Footer, Header, Container } from 'libs/layout';
import { useAppSelector, USER_FEATURE_KEY, RootState } from 'libs/data-store';
import { Content } from './layout-private.styled';

interface ILayoutPrivate {
  children: JSX.Element;
  toLoginPath: string;
  isAdmin?: boolean;
}

export function LayoutPrivate(props: ILayoutPrivate): JSX.Element {
  //console.log('🚀 ~ LayoutPrivate ~ props:', props);
  const userLogined = useAppSelector((state: RootState) => state[USER_FEATURE_KEY].userLogined);
  //const admin = useAppSelector((state: RootState) => state[USER_FEATURE_KEY].admin);
  //const { isAdmin } = props;

  /**
   * is logined
   */
  if (!userLogined) {
    return <Navigate to={props.toLoginPath} />;
  }

  return (
    <LayoutBase>
      <Header />
      <Container>
        <Content>{props.children}</Content>
      </Container>
      <Footer />
    </LayoutBase>
  );
}

export default LayoutPrivate;
