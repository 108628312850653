import { useAppSelector, USER_FEATURE_KEY, IUserState } from 'libs/data-store';
import { userService } from 'libs/services';
import { Button } from 'libs/mui';
import { UserContent } from './user.styled';

export function User() {
  const user: IUserState = useAppSelector((state: any) => state[USER_FEATURE_KEY]);
  if (!user.userLogined) {
    return null;
  }

  const logout = () => {
    userService.logout();
  };

  return (
    <UserContent>
      <Button onClick={logout} size="small">Выход</Button>
    </UserContent>
  );
}

export default User;
