import { config } from 'app/config';
import { LocalStorage, notify } from 'libs/common';
import { AxiosTransport, JSONProtocol } from 'libs/api';
import { getApiError } from './func';
import { BstrApi } from './bstr-api';

const params = {
  apiUrl: config.apiUrl,
  timeout: config.failAfter,
  debug: config.debug,
  TransportClass: AxiosTransport,
  ProtocolClass: JSONProtocol,
  StorageClass: LocalStorage,
  getErrorMessage: getApiError,
  notify,
};
export const api = new BstrApi(params);

export default api;
