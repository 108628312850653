import { useLocation, Navigate } from 'react-router-dom';

import { links } from 'libs/data-config';
import { LayoutBase, Container, Footer } from 'libs/layout';
import { useAppSelector, USER_FEATURE_KEY, RootState } from 'libs/data-store';
import { Content } from './layout-public.styled';

interface IProps {
  children: JSX.Element | JSX.Element[];
  toLoginedPath: string;
}

export function LayoutPublic(props: IProps): JSX.Element {
  const { toLoginedPath } = props;
  const location = useLocation();
  const isLoginPath = location.pathname === links.login;
  //console.log('🚀 ~ LayoutPublic ~ location', location);
  const userLogined: boolean = useAppSelector((state: RootState) => state[USER_FEATURE_KEY].userLogined);

  if (userLogined && isLoginPath) {
    console.log('🚀 navigate to logined', toLoginedPath);
    return <Navigate to={toLoginedPath} />;
  }

  return (
    <LayoutBase>
      <Container vcenter>
        <Content>{props.children}</Content>
      </Container>
      <Footer />
    </LayoutBase>
  );
}

export default LayoutPublic;
